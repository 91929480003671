import { log } from 'common/log';

const initializeOrUpdateServiceWorker = async (
  registration: ServiceWorkerRegistration,
) => {
  registration.addEventListener('updatefound', async () => {
    const newWorker = registration.installing as ServiceWorker;

    log.info(`service worker registration status: ${registration}`);
    log.info(`service worker controller found an update: ${newWorker}`);
    newWorker.addEventListener('statechange', async () => {
      // If the new service worker is installed
      log.info(`new worker state in state change: ${newWorker.state}`);

      if (newWorker.state === 'installed') {
        // Check if there is an active service worker
        log.info(
          `controller and active:
          ${navigator.serviceWorker.controller} - 
          ${registration.active}`,
        );
        if (navigator.serviceWorker.controller && registration.active) {
          log.info(
            'Service worker updated... Activating service worker update',
          );
        } else {
          log.info('Service worker registered.');
          // This is the first service worker being installed, no need to prompt
          log.info('Service worker installed for the first time.');
        }
      }
    });
  });
};

export const registerServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', async () => {
      await navigator.serviceWorker
        .register('/sw.js')
        .then((registration) => initializeOrUpdateServiceWorker(registration))
        .catch((err) => {
          window.alert(
            'Something Went Wrong! Offline capabilities will not be possible. Please contact the administrators.',
          );
          log.error('Service worker could not be registered', err);
        });
    });
  }
};
