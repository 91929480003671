const heading = (...text: string[]): void => {
  console.log(`%c${text}`, 'color: black; font-size: 35px;');
};

const warning = (...text: string[]): void => {
  console.log(`%c${text}`, 'color: orange');
};

const error = (...text: string[]): void => {
  console.log(`%c${text}`, 'color: red');
};

const info = (...text: string[]): void => {
  console.log(`%c${text}`, 'color: green');
};

const debug = (...text: string[]): void => {
  console.log(`%c${text}`, 'color: blue');
};

export const log = {
  heading,
  warning,
  error,
  info,
  debug,
};
