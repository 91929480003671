import 'core-js/stable';
import 'regenerator-runtime/runtime';

import { log } from 'common/log';
import { environment } from 'common/environment';
import { registerServiceWorker } from 'applications/offlineClient/registerServiceWorker';

import 'normalize.css';
import 'applications/offlineClient/index.scss';

const initializeApryse = async () => {
  try {
    log.info('Initializing PDFTron...');

    const core = (window as any).Core; // eslint-disable-line
    core.setWorkerPath('/webviewer/core');
    await core.PDFNet.initialize(environment.pdfTronLicense);
  } catch {
    log.error('Can not initialize PDFTron in initializeApyrse');
  }
};

const main = async () => {
  // const appId = generateAppId();
  log.heading('DRIVE NDT UI Offline Client');
  log.info(`Version ${environment.branch}:${environment.version}`);
  log.info(`Package ${environment.npmVersion}`);
  log.info(`Default Server: ${environment.defaultDriveUrl}`);
  // log.info(`Loading application ${appId}...`);

  const application = await import('applications/offlineClient/application');
  registerServiceWorker();
  await application.bootstrap();
  await initializeApryse();
};

main().catch((err) => {
  log.error(`Application crashed ${err}`);
  log.debug(err.stack);
});
