import packageJson from '../../package.json';

// Values are replaces by webpack at build time;
declare const BRANCH: string;
declare const VERSION: string;
declare const TARGET: string;
declare const APP: string;

export type TEnvironment = 'Development' | 'Production';

export const environment = {
  branch: BRANCH,
  version: VERSION,
  app: APP,
  target: TARGET,
  npmVersion: packageJson.version,
  port: process.env['OFFLINE_CLIENT_PORT'],
  mode: (process.env['NODE_ENV'] === 'development'
    ? 'Development'
    : 'Production') as TEnvironment,
  jwtVerificationKey: process.env['JWT_SECRET'] as string,
  jwtAlgorithm: process.env['JWT_ALGORITHM'] as string,
  defaultDriveUrl: process.env['DEFAULT_DRIVE_URL'] as string,
  logApiMessages: process.env['LOG_API_MESSAGES'] === 'true',
  pdfTronLicense: process.env['PDF_TRON_LICENSE'] as string,
};
